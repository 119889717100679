<template>
    <div class="w-full confirmAssessmentWrapper">
        <div class="w-full rounded gap-6 confirm">
            <div class="flex w-full rounded px-4 pb-6 pt-6 flex-col bg-[#2195f311] items-center justify-center">
                <h1 class="text-lg text-center font-semibold !text-[#1b2559]">Select your success score manually</h1>
                <div class="slider">
                    <input
                        type="range"
                        min="1"
                        max="100"
                        v-model="sliderValue"
                        id="success-slider"
                        :style="{
                            background: `linear-gradient(90deg, #2196f3 ${sliderValue}%, #D9D9D9 ${sliderValue}%)`,
                        }"
                    />
                    <div class="flex items-center border border-[1.5px] border-[#e5e7eb] !text-[#1b2559] bg-white p-2 rounded">
                        <input class="outline-none w-[47px] font-bold !text-lg !text-[#1b2559] p-0" type="number" min="1" max="100" v-model="sliderValue" />
                        <span class="text-lg !text-[#1b2559] font-bold">%</span>
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-2 w-full">
                <span class="recentapp">Selected Tests</span>
                <div class="w-full">
                    <table class="min-w-full text-sm">
                        <thead class="text-[#64748B] bg-[#FBFBFB]">
                            <tr class="transition-colors hover:bg-muted/50">
                                <th class="h-14 px-4 w-2/4 text-left font-medium">Title</th>
                                <th class="h-14 px-4 w-1/4 text-start font-medium">Category</th>
                                <th class="h-14 px-4 text-start font-medium">Duration</th>
                                <th class="h-14 px-4 text-center font-medium">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(assess, index) in selectedAssessments.filter((assessment) => assessment.assessment !== null && assessment.assessment.category !== 'Custom')"
                                :key="index"
                                class="border-b-[1px] border-[#e5e7eb] transition"
                            >
                                <td class="h-14 px-4 align-middle overflow-x-scroll">
                                    {{ assess?.assessment?.name }}
                                </td>
                                <td class="h-14 px-4 text-start align-middle">
                                    {{ assess?.assessment?.category }}
                                </td>
                                <td class="h-14 px-4 text-start align-middle">
                                    <font-awesome-icon :icon="['far', 'clock']" class="mr-1" />
                                    {{ assess?.assessment?.questions_nbr > 25 ? parseInt((20 * 35) / 60) : parseInt((assess?.assessment?.questions_nbr * 35) / 60) }}'
                                </td>
                                <td class="h-14 px-4 align-middle">
                                    <div class="flex justify-center gap-3 items-center">
                                        <Popper :content="$t('Move up')" placement="bottom" :hover="true">
                                            <button
                                                :disabled="index === 0"
                                                :style="`cursor: ${index === 0 ? 'not-allowed' : 'pointer'}; opacity:${index === 0 ? '.4' : '1'};`"
                                                @click="moveTestUp(assess.assessment)"
                                            >
                                                <font-awesome-icon :icon="['fas', 'angle-up']" />
                                            </button>
                                        </Popper>
                                        <Popper :content="$t('Move down')" placement="bottom" :hover="true">
                                            <button
                                                :disabled="selectedAssessments[index + 1]?.assessment == null"
                                                :style="`cursor: ${selectedAssessments[index + 1]?.assessment == null ? 'not-allowed' : 'pointer'}; opacity:${
                                                    selectedAssessments[index + 1]?.assessment == null ? '.4' : '1'
                                                };`"
                                                @click="moveDownObject(assess.assessment)"
                                            >
                                                <font-awesome-icon :icon="['fas', 'angle-down']" />
                                            </button>
                                        </Popper>
                                        <Popper :content="$t('Delete')" placement="bottom" :hover="true">
                                            <button class="ml-3" @click="removeAssessment(assess?.assessment)">
                                                <font-awesome-icon :icon="['far', 'trash-can']" />
                                            </button>
                                        </Popper>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="flex flex-col gap-2 w-full">
                <span v-if="customQst.length > 0" class="recentapp">Selected Questions</span>
                <div v-if="customQst.length > 0" class="w-full">
                    <table class="min-w-full text-sm">
                        <thead class="text-[#64748B] border-b-[1px] bg-[#FBFBFB] border-[#e5e7eb]">
                            <tr class="transition-colors hover:bg-muted/50">
                                <th class="h-14 px-4 w-2/4 text-left font-medium">Title</th>
                                <th class="h-14 px-4 w-1/4 text-start font-medium">Category</th>
                                <th class="h-14 px-4 text-start font-medium">Duration</th>
                                <th class="h-14 px-4 text-center font-medium">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(assess, index) in customQst" :key="index" class="border-b-[1px] border-[#e5e7eb] transition">
                                <td class="h-14 px-4 align-middle overflow-x-scroll">
                                    {{ assess?.name }}
                                </td>
                                <td class="h-14 px-4 text-start align-middle">
                                    {{ assess?.category }}
                                </td>
                                <td class="h-14 px-4 text-start align-middle">
                                    <font-awesome-icon :icon="['far', 'clock']" class="mr-1" />
                                    {{ assess?.time }}'
                                </td>
                                <td class="h-14 px-4 align-middle">
                                    <div class="flex justify-center gap-3 items-center">
                                        <Popper :content="$t('Move up')" placement="bottom" :hover="true">
                                            <button
                                                :disabled="index === 0"
                                                :style="`cursor: ${index === 0 ? 'not-allowed' : 'pointer'}; opacity:${index === 0 ? '.4' : '1'}; `"
                                                @click="moveQstUp(index)"
                                            >
                                                <font-awesome-icon :icon="['fas', 'angle-up']" />
                                            </button>
                                        </Popper>
                                        <Popper :content="$t('Move down')" placement="bottom" :hover="true">
                                            <button
                                                :disabled="index === customQst.length - 1"
                                                :style="`cursor: ${index === customQst.length - 1 ? 'not-allowed' : 'pointer'}; opacity:${index === customQst.length - 1 ? '.4' : '1'}; `"
                                                @click="moveQstDown(index)"
                                            >
                                                <font-awesome-icon :icon="['fas', 'angle-down']" />
                                            </button>
                                        </Popper>
                                        <Popper :content="$t('Delete')" placement="bottom" :hover="true">
                                            <button class="ml-3" @click="deleteQst(index)">
                                                <font-awesome-icon :icon="['far', 'trash-can']" />
                                            </button>
                                        </Popper>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConfirmNewAssessment",
    props: {
        assessments: {
            type: Array,
            required: true,
        },
        moveQstUp: { type: Function },
        moveQstDown: { type: Function },
        deleteQst: { type: Function },
        customQst: {
            type: Array,
        },
        removeAssessment: {
            type: Function,
        },
        assessment: {
            type: Object,
        },
        moveTestUp: {
            type: Function,
        },
        moveDownObject: {
            type: Function,
        },
        setScore: {
            type: Function,
        },
    },
    data() {
        return {
            selectedAssessments: this.assessments,
            sliderValue: 70,
        };
    },
    watch: {
        selectedAssessments(newVal) {
            console.log(newVal);
        },
        sliderValue(newVal) {
            this.setScore(newVal);
        },
    },
};
</script>

<style scoped lang="scss">
.recentapp {
    color: #1b2559;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
    width: 90%;
}

.confirmAssessmentWrapper {
    background-color: #fff;

    .confirm {
        padding: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .title {
            color: #1b2559;
            font-size: 19px;
            font-weight: 500;
        }

        .projectData {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            gap: 20px;

            div {
                text-align: center;
                display: flex;
                flex-direction: column;
                gap: 10px;

                h3 {
                    font-size: 18px;
                    font-weight: 600;
                }

                p {
                    font-size: 16px;
                }
            }
        }

        .Assessments {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0.5rem 1rem;
                gap: 10px;

                &:hover {
                    background-color: #a7c6e3;
                }

                h3 {
                    font-size: 16px;
                    font-weight: 600;
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }
}

.slider {
    display: flex;
    align-items: center;
    width: 100%;
}

#success-slider {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 5px;
    margin-right: 48px;
    width: 100%;
    height: 8px;
}

#success-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #2196f3;
    cursor: pointer;
}
</style>
